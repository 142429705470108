<div
  class="title"
  style="display: flex; justify-content: flex-start; align-content: flex-start"
>
  {{ title }}
</div>

<div *ngFor="let val of values">
  <div
    class="subtitle"
    style="
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
    "
  >
    {{ val.tag }}
  </div>
  <div
    class="value"
    style="width: 220px; height: 80px; text-align: center; line-height: 60px"
  >
    <div *ngIf="val.uom === 'DKK'">{{ val.value | currency: "DKK " }}</div>
    <div *ngIf="!(val.uom === 'DKK')">{{ val.value }}{{ " " }}{{ uom }}</div>
  </div>
</div>
