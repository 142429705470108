import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DateConverter } from '../utils/date-converter';
import {
  TimeSeriesSet,
  TimePeriod,
  BooleanTimeSeries,
} from '../models/timeseries.model';
import {
  mean,
  round,
  extractTimePeriods,
  conditionalMean,
} from '../utils/time-period-utils';
import { Charts } from '../models/line-config.model';
import { Measurable } from '../../assets/settings/models/measurable.model';
import { KPIs, OperatingConditions } from '../../assets/settings/measurable';
import {
  MonthOptions,
  Gauges,
  generateMonthOptions,
  getStartEndDates,
  setupParamGauges,
  setupStatusValues,
  DisplayValues,
} from './helper/monthly.component.helper';
import { TimeSeriesService } from '../services/time-series.service';
import { CompensationService } from '../services/compensation.service';

const resolution: string = '1h'; // 1h resolution for timeseries data

@Component({
  selector: 'app-monthly',
  templateUrl: './monthly.component.html',
  styleUrl: './monthly.component.scss',
})
export class MonthlyComponent implements OnInit {
  selectedMonth: string = '';
  months: MonthOptions[] = [];
  timeseries: TimeSeriesSet = {};
  params: string[] = [];
  paramGauges: Gauges = {};
  statusDisplay: DisplayValues = {};
  statusTags: string[] = [];
  avgParams: string[] = [];
  kpiCharts: Charts = {};
  kpiTags: string[] = [];
  validFlag: TimePeriod[] = [];
  reportready: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dateConverter: DateConverter,
    private breakpointObserver: BreakpointObserver,
    private timeseriesService: TimeSeriesService,
    private compensationService: CompensationService,
  ) {}

  // responsive column display
  cols$: Observable<number> = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall, Breakpoints.Medium])
    .pipe(
      map((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          return 1;
        } else if (result.breakpoints[Breakpoints.Small]) {
          return 2;
        } else if (result.breakpoints[Breakpoints.Medium]) {
          return 3;
        } else {
          return 4;
        }
      }),
      shareReplay(),
    );

  colsStatus$: Observable<number> = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall, Breakpoints.Medium])
    .pipe(
      map((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          return 1;
        } else if (result.breakpoints[Breakpoints.Small]) {
          return 2;
        } else if (result.breakpoints[Breakpoints.Medium]) {
          return 3;
        } else {
          return 5;
        }
      }),
      shareReplay(),
    );

  chartCols$: Observable<number> = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall, Breakpoints.Medium])
    .pipe(
      map((result) => {
        if (
          result.breakpoints[Breakpoints.XSmall] ||
          result.breakpoints[Breakpoints.Small] ||
          result.breakpoints[Breakpoints.Medium]
        ) {
          return 1;
        } else {
          return 2;
        }
      }),
      shareReplay(),
    );

  ngOnInit(): void {
    this.reportready = false;
    this.months = generateMonthOptions();
    this.statusDisplay = setupStatusValues();
    this.statusTags = Object.keys(this.statusDisplay);
    this.params = OperatingConditions.map((param) => param.tag);
    this.paramGauges = setupParamGauges();
    this.avgParams = Object.keys(this.paramGauges);

    // setup charts for KPIs
    KPIs.forEach((kpi: Measurable) => {
      this.kpiCharts[kpi.tag] = {
        id: 'kpi-chart-' + kpi.tag,
        linechartconfig: {
          title: kpi.name,
          yAxisLabel: kpi.uom! == '' ? '' : `[${kpi.uom}]`,
          yAxisMin: kpi.zero!,
          yAxisMax: kpi.peak!,
          decimals: 1,
        },
        tag: kpi.tag,
        data: {},
        lower: kpi.min!,
        upper: kpi.max!,
        validPeriods: [],
      };
    });
    this.kpiTags = KPIs.map((kpi) => kpi.tag);

    // set selectedMonth from url params
    this.selectedMonth = this.interpretDateInterval();
    this.update();
  }

  update(): void {
    this.reportready = false;
    // get start and end date from selectedMonth
    this.selectedMonth = this.interpretDateInterval();
    const [start, end] = getStartEndDates(this.selectedMonth);

    //TODO: All influx specific things should be handled in api. use datetime format "yyyy'-'MM'-'dd'T'HH':'mm':'ss'.'fff'Z'"
    var startConverted = this.dateConverter.toInfluxFormat(start);
    var endConverted = this.dateConverter.toInfluxFormat(end);
    console.log(startConverted, endConverted);

    // Request timeseries data
    var tags = this.params.concat(this.kpiTags);
    this.timeseriesService
      .getTimeseries(
        tags,
        startConverted,
        endConverted,
        resolution,
        true,
        true,
        true,
      )
      .subscribe((resp) => {
        this.timeseries = resp.data;
        this.validFlag = resp.flag.hasOwnProperty('ValidityFlag.Value')
          ? extractTimePeriods(resp.flag['ValidityFlag.Value'])
          : [];

        let activityFlag: BooleanTimeSeries = {};
        if (
          Object(this.timeseries).hasOwnProperty('HeatPumpSelected') &&
          Object(this.timeseries).hasOwnProperty('CurrentSequencerMode')
        ) {
          Object.entries(this.timeseries['HeatPumpSelected']).forEach(
            ([key, value], index) => {
              let mode = round(this.timeseries['CurrentSequencerMode'][key], 0);
              activityFlag[key] = value === 1 && mode === 1;
            },
          );
        }
        this.params.forEach((tag) => {
          let value = conditionalMean(this.timeseries[tag], activityFlag);
          value = round(value, this.paramGauges[tag].config.decimals ?? 1);
          this.paramGauges[tag].value = value;
        });

        this.kpiTags.forEach((tag) => {
          this.kpiCharts[tag].data = this.timeseries.hasOwnProperty(tag)
            ? this.timeseries[tag]
            : {};
          this.kpiCharts[tag].validPeriods = this.validFlag;
        });
        this.reportready = true;
      });

    // Request compensation and performance
    this.compensationService
      .getCompensation(startConverted, endConverted, resolution)
      .subscribe((resp) => {
        this.statusDisplay['compensation'].value = round(
          resp.compensation_DKK,
          1,
        );
        this.statusDisplay['heating_effect_kWh'].values = [
          {
            tag: 'Total Heating',
            value: round(resp.total_heating_effect_kWh, 1),
            uom: 'kWh',
          },
          {
            tag: 'Total During Valid Periods',
            value: round(resp.active_heating_effect_kWh, 1),
            uom: 'kWh',
          },
          {
            tag: 'Total Under-Performance',
            value: round(resp.heating_effect_kWh, 1),
            uom: 'kWh',
          },
        ];
        this.statusDisplay['cooling_icewater_kWh'].values = [
          {
            tag: 'Total Cooling to Ice Water',
            value: round(resp.total_cooling_icewater_kWh, 1),
            uom: 'kWh',
          },
          {
            tag: 'Total During Valid Periods',
            value: round(resp.active_cooling_icewater_kWh, 1),
            uom: 'kWh',
          },
          {
            tag: 'Total Under-Performance',
            value: round(resp.cooling_icewater_kWh, 1),
            uom: 'kWh',
          },
        ];
        this.statusDisplay['electricity_consumption_kWh'].values = [
          {
            tag: 'Total Electricity Consumption',
            value: round(resp.total_electricity_consumption_kWh, 1),
            uom: 'kWh',
          },
          {
            tag: 'Total During Valid Periods',
            value: round(resp.active_electricity_consumption_kWh, 1),
            uom: 'kWh',
          },
          {
            tag: 'Total Under-Performance',
            value: round(resp.electricity_consumption_kWh, 1),
            uom: 'kWh',
          },
        ];
        this.statusDisplay['valid_hours'].values = [
          {
            tag: 'Total Operating Hours in Mode 1',
            value: resp.total_hours_mode_1 ?? 0,
            uom: 'hours',
          },
          {
            tag: 'Total Operating Hours in Mode 2',
            value: resp.total_hours_mode_2 ?? 0,
            uom: 'hours',
          },

          {
            tag: 'Total Valid Hours',
            value: resp.total_valid_hours ?? 0,
            uom: 'hours',
          },
        ];
        // this.paramGauges['valid_time'].value = round(
        //   (resp.valid_hours / resp.total_hours) * 100,
        //   this.paramGauges['valid_time'].config.decimals ?? 1,
        // );
      });
  }

  getMonthFromDateString(datestr: string): string {
    const regex = new RegExp('([0-9]{4})([0-9]{2})');
    const match = regex.exec(datestr);
    if (match && match.length == 3) {
      return `${match[1]}/${match[2]}`;
    }
    return '';
  }

  interpretDateInterval(): string {
    const regex = new RegExp('([0-9]{6})');
    let start = '';
    // interpret date interval from url params
    this.route.params.subscribe((params) => {
      // interpret query string and match to regex
      const match = regex.exec(params['month']);
      // if match and 2 matches (full match + 1 group)
      if (match && match.length == 2) {
        start = match[1];
      }
    });
    return start;
  }

  navigateToNewDateInterval(month: string): void {
    const urlSegments = this.route.snapshot.url;
    const parentUrlSegments = urlSegments.slice(0, -1); // Select all but the last segment
    let parentRoute = parentUrlSegments
      .map((segment) => segment.path)
      .join('/');
    console.log(parentRoute);
    console.log(month);
    this.router.navigate([`${parentRoute}/${month}`]).then(() => {
      this.update(); // Call update() after navigation is complete
    });
  }

  onDropdownChange(value: string): void {
    this.reportready = false;
    this.selectedMonth = value;
    console.log(value);
    this.navigateToNewDateInterval(`${value}`);
  }
}
