import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { dateRange } from './datetime-selector/datetime-selector.component';
import { TimeSeriesService } from '../services/time-series.service';
import {
  TimeSeriesSet,
  TimePeriod,
  TimeSeries,
} from '../models/timeseries.model';
import { extractTimePeriods } from '../utils/time-period-utils';
import { Charts } from '../models/line-config.model';
import {
  OperatingConditions,
  KPIs,
  HeatPumpConditions,
} from '../../assets/settings/measurable';
import { Measurable } from '../../assets/settings/models/measurable.model';

type ResolutionIntervalsMap = {
  [key: number]: string;
};

const resolutionIntervals: ResolutionIntervalsMap = {
  1: '10m',
  7: '10m',
  30: '10m',
  90: '30m',
  365: '1h',
};

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.scss',
})
export class AnalyticsComponent implements OnInit {
  dates: dateRange | null = null;
  tags: string[] = [];
  dataready: boolean = false;
  timeseries: TimeSeriesSet = {};
  validFlag: TimePeriod[] = [];
  charts: Charts = {};
  mode: TimeSeries = {};

  constructor(
    private breakpointObserver: BreakpointObserver,
    private timeseriesService: TimeSeriesService,
  ) {}

  //  responsive column display - either cols = 1 || 2
  cols$: Observable<number> = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall, Breakpoints.Medium])
    .pipe(
      map((result) => {
        if (
          result.breakpoints[Breakpoints.XSmall] ||
          result.breakpoints[Breakpoints.Small] ||
          result.breakpoints[Breakpoints.Medium]
        ) {
          return 1;
        } else {
          return 2;
        }
      }),
      shareReplay(),
    );

  getTimeSeries(start: string, end: string, resolution: string): void {
    this.timeseriesService
      .getTimeseries([...new Set(this.tags)], start, end, resolution, true)
      .subscribe((resp) => {
        this.dataready = true;
        this.timeseries = resp.data;
        this.validFlag = resp.flag.hasOwnProperty('ValidityFlag.Value')
          ? extractTimePeriods(resp.flag['ValidityFlag.Value'])
          : [];
        this.tags.forEach((tag) => {
          this.charts[tag].data = this.timeseries.hasOwnProperty(tag)
            ? this.timeseries[tag]
            : {};
          this.charts[tag].validPeriods = this.validFlag;
        });
        this.mode = this.timeseries.hasOwnProperty('CurrentSequencerMode')
          ? this.timeseries['CurrentSequencerMode']
          : {};
      });
  }

  getResolutionInterval(): string {
    let duration =
      new Date(this.dates?.end!).getTime() -
      new Date(this.dates?.start!).getTime(); // ms
    duration = duration / 1000 / 60 / 60 / 24; // days
    let resolution_key = 1;
    Object.keys(resolutionIntervals).forEach((val) => {
      let value = Number(val);
      if (duration >= value) {
        resolution_key = value;
      }
    });
    const resolution = resolutionIntervals[resolution_key];
    return resolution;
  }

  updateCharts(start: string, end: string): void {
    const res = this.getResolutionInterval();
    this.getTimeSeries(start, end, res);
  }

  getDates(dates: dateRange): void {
    this.dates = dates;
    this.updateCharts(this.dates?.start!, this.dates?.end!);
  }

  ngOnInit(): void {
    const params = OperatingConditions.map((param) => param.tag);
    const kpis = KPIs.map((kpi) => kpi.tag);
    const heatpumptags = HeatPumpConditions.map((param) => param.tag);
    this.tags = kpis.concat(params).concat(heatpumptags);

    // setup KPI charts
    KPIs.forEach((kpi: Measurable) => {
      this.charts[kpi.tag] = {
        id: 'adv-kpi-chart-' + kpi.tag,
        linechartconfig: {
          title: kpi.name,
          yAxisLabel: kpi.uom! == '' ? '' : `[${kpi.uom}]`,
          yAxisMin: kpi.zero!,
          yAxisMax: kpi.peak!,
        },
        tag: kpi.tag,
        data: {},
        lower: kpi.min!,
        upper: kpi.max!,
        validPeriods: [],
      };
    });

    // setup param charts
    OperatingConditions.forEach((param: Measurable) => {
      this.charts[param.tag] = {
        id: 'adv-param-chart-' + param.tag,
        linechartconfig: {
          title: param.name,
          yAxisLabel: `[${param.uom}]`,
          yAxisMin: param.zero!,
          yAxisMax: param.peak!,
        },
        tag: param.tag,
        data: {},
        lower: param.min!,
        upper: param.max!,
        validPeriods: [],
      };
    });

    HeatPumpConditions.forEach((param: Measurable) => {
      this.charts[param.tag] = {
        id: 'adv-param-chart-' + param.tag,
        linechartconfig: {
          title: param.name,
          yAxisLabel: '',
          yAxisMin: param.zero!,
          yAxisMax: param.peak!,
        },
        tag: param.tag,
        data: {},
        validPeriods: [],
      };
    });
  }
}
