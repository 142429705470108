<div class="content">
  <app-datetime-selector (dates)="getDates($event)"></app-datetime-selector>

  <mat-grid-list [cols]="cols$ | async" rowHeight="520px">
    <mat-grid-tile *ngFor="let tag of tags" [colspan]="1" [rowspan]="1">
      <div *ngIf="!dataready" class="loading-container">
        <p-progressSpinner
          animationDuration="2s"
          strokeWidth="4"
        ></p-progressSpinner>
      </div>
      <div *ngIf="dataready">
        <app-line
          [id]="charts[tag].id"
          [tag]="tag"
          [lineconfig]="charts[tag].linechartconfig"
          [timeseries]="charts[tag].data"
          [upper]="charts[tag].upper"
          [lower]="charts[tag].lower"
          [valid]="charts[tag].validPeriods"
          [enableLimitToggle]="true"
        ></app-line>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <app-runs-table
    [timeseries]="timeseries"
    [modeTimeSeries]="mode"
  ></app-runs-table>
</div>
