import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EnvironmentConfiguration,
  ENVIRONMENT_CONFIG,
} from '../../environments/models/environment.model';

import { CompensationResponse } from '../monthly/helper/monthly.component.helper';

@Injectable({
  providedIn: 'root',
})
export class CompensationService {
  constructor(
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration,
  ) {}

  getCompensation(
    start: string,
    end: string,
    resolution: string,
  ): Observable<CompensationResponse> {
    var baseUrl = this.environment.serviceCall.baseURL!;
    baseUrl = baseUrl.slice(-1) === '/' ? baseUrl : baseUrl + '/';
    baseUrl = `${baseUrl}telemetry/compensation`;
    return this.httpClient.post<CompensationResponse>(baseUrl, {
      start,
      end,
      resolution,
    });
  }
}
