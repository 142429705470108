import { Measurable } from './models/measurable.model';

export const KPIs: Measurable[] = [
  {
    tag: 'KPI_HeatEffectMainHeaterPV',
    name: 'Heating Effect Main Heater',
    uom: 'kW',
    min: 860,
    design: 910,
    max: 1035,
    zero: 600,
    peak: 1200,
  },
  {
    tag: 'KPI_CoolingIceWaterPV',
    name: 'Cooling for Ice Water',
    uom: 'kW',
    min: 300,
    design: 335,
    max: 450,
    zero: 200,
    peak: 600,
  },
  {
    tag: 'KPI_CompElecInputPV',
    name: 'Electricity Consumption',
    uom: 'kW',
    min: 360,
    design: 365,
    max: 390,
    zero: 200,
    peak: 600,
  },
  {
    tag: 'COP',
    name: 'COP Heating',
    uom: '',
    min: 0,
    design: 3.0,
    max: 3.5,
    zero: 0,
    peak: 3.5,
  },
];

export const OperatingConditions: Measurable[] = [
  {
    tag: 'MainAirTempBeforePreheaterPV',
    name: 'Dryer Main Air Inlet Temperature',
    uom: '\u00b0C',
    min: 15,
    design: 25,
    max: 30,
    peak: 45,
    zero: 10,
  },
  {
    tag: 'MainAirFlowRatePV',
    name: 'Main Air Flow Rate',
    uom: 'kg/h',
    min: 32500,
    design: 33500,
    max: 34500,
    peak: 40000,
    zero: 30000,
  },
  {
    tag: 'CowWaterFlowPV',
    name: 'Mass Flow Rate of COW Water',
    uom: 'kg/h',
    min: 10800,
    design: 14400,
    max: 18000,
    peak: 35000,
    zero: 0,
  },
  {
    tag: 'CowWaterTempPV',
    name: 'Temperature of COW Water',
    uom: '\u00b0C',
    min: 35,
    design: 40,
    max: 60,
    zero: 20,
    peak: 65,
  },
  {
    tag: 'IceWaterTempBeforeBoosterPV',
    name: 'Temperature of Ice Water',
    uom: '\u00b0C',
    min: 4.5,
    design: 5,
    max: 8,
    zero: 0,
    peak: 10,
  },
  {
    tag: 'CondFlowEcoPV',
    name: 'Mass Flow Rate of Condensate Steam Heater',
    uom: 'kg/h',
    min: 830,
    design: 945,
    max: 1065,
    zero: 700,
    peak: 1300,
  },
  {
    tag: 'CondTempBeforeEcoPV',
    name: 'Temperature of Condensate Steam Heater',
    uom: '\u00b0C',
    min: 135,
    design: 140,
    max: 150,
    zero: 110,
    peak: 160,
  },
];

export const HeatPumpConditions: Measurable[] = [
  {
    tag: 'HeatPumpSelected',
    name: 'Heat Pump',
    uom: '',
    min: 0,
    design: 1,
    max: 1,
    zero: 0,
    peak: 1,
  },
  {
    tag: 'CurrentSequencerMode',
    name: 'Current Sequencer Mode',
    uom: '',
    min: 0,
    design: 1,
    max: 2,
    zero: 0,
    peak: 5,
  },
];
