import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { MsalGuard } from '@azure/msal-angular';

import { DashboardComponent } from './dashboard/dashboard.component';
import { MonthlyComponent } from './monthly/monthly.component';
import { ReportComponent } from './monthly/report/report.component';
import { AnalyticsComponent } from './analytics/analytics.component';

export const routes: Routes = [
  {
    path: 'dashboard',
    title: 'Dashboard - InsightPartner - AddCool',
    component: DashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'advanced',
    title: 'Advanced View - InsightPartner - AddCool',
    component: AnalyticsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'report/:month',
    title: 'Monthly Report - InsightPartner - AddCool',
    component: MonthlyComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'report',
    title: 'Monthly Report - InsightPartner - AddCool',
    component: ReportComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
