import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TimeSeriesSet } from '../models/timeseries.model';
import {
  OperatingConditions,
  KPIs,
  HeatPumpConditions,
} from '../../assets/settings/measurable';
import { Measurable } from '../../assets/settings/models/measurable.model';
import { round } from '../utils/time-period-utils';
import { red, green, blue } from '../utils/def-constants';
import { GaugeConfig } from '../models/gauge-config.model';
import { TimeSeriesService } from '../services/time-series.service';

interface HeatPumpMode {
  title: string;
  mode: number | null;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit, OnDestroy {
  updateTime: Date = new Date();
  timer: any;
  value: number = 0;
  kpis: string[] = [];
  params: string[] = [];
  timeseries: TimeSeriesSet = {};
  tsvalue: number = 0;
  gcfg: GaugeConfig = {
    title: 'a',
    subtitle: 'b',
    min: 0,
    max: 100,
    levels: [],
    uom: 'c',
  };
  kpiGauges: {
    [tag: string]: {
      id: string;
      tag: string;
      config: GaugeConfig;
      value: number;
      trigger: boolean;
      label?: string;
    };
  } = {};
  opGauges: {
    [tag: string]: {
      id: string;
      tag: string;
      config: GaugeConfig;
      value: number;
      trigger: boolean;
      label?: string;
    };
  } = {};
  dataready: boolean = false;
  tags: string[] = [];
  heatPumpModes: HeatPumpMode[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private timeseriesService: TimeSeriesService,
  ) {}

  // responsive column display
  cols$: Observable<number> = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall, Breakpoints.Medium])
    .pipe(
      map((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          return 1;
        } else if (result.breakpoints[Breakpoints.Small]) {
          return 2;
        } else if (result.breakpoints[Breakpoints.Medium]) {
          return 3;
        } else {
          return 4;
        }
      }),
      shareReplay(),
    );

  setupOperatingConditionGauges(param: Measurable): void {
    let config: GaugeConfig = {
      title: param.name,
      subtitle: 'Current Value',
      uom: param.uom!,
      min: param.zero!,
      max: param.peak!,
      decimals: 1,
      levels: [
        { value: param.min!, color: red },
        { value: param.max!, color: green },
        { value: param.peak!, color: red },
      ],
    };
    this.opGauges[param.tag] = {
      id: 'gauge-param-' + param.tag,
      tag: param.tag,
      config: config,
      value: 0,
      trigger: true,
      label: '',
    };
  }

  setupKPIGauge(kpi: Measurable): void {
    let levels = [];
    if (
      kpi.tag === 'KPI_HeatEffectMainHeaterPV' ||
      kpi.tag === 'KPI_CoolingIceWaterPV'
    ) {
      levels = [
        { value: kpi.min!, color: red },
        { value: kpi.peak!, color: green },
      ];
    } else if (kpi.tag == 'KPI_CompElecInputPV') {
      levels = [
        { value: kpi.max!, color: green },
        { value: kpi.peak!, color: red },
      ];
    } else {
      levels = [{ value: kpi.peak!, color: blue }];
    }
    const config: GaugeConfig = {
      title: kpi.name,
      subtitle: 'Current Value',
      uom: kpi.uom!,
      min: kpi.zero!,
      max: kpi.peak!,
      decimals: 1,
      levels: levels,
    };
    this.kpiGauges[kpi.tag] = {
      id: 'gauge-' + kpi.tag,
      tag: kpi.tag,
      config: config,
      value: 0,
      trigger: true,
      label: '',
    };
  }

  updateGaugeValue(tag: string, timeseries: TimeSeriesSet): number {
    let value = timeseries.hasOwnProperty(tag)
      ? Object.values(timeseries[tag] || {})[0]
      : 0.0;
    value = round(value, 2); // round to 2 decimals
    return value;
  }

  update(): void {
    this.tags = KPIs.map((kpi) => kpi.tag!)
      .concat(OperatingConditions.map((param) => param.tag!))
      .concat(HeatPumpConditions.map((param) => param.tag!));

    // extract time series data
    this.timeseriesService
      .getTimeseries([...new Set(this.tags)], null, null, null, true)
      .subscribe((resp) => {
        this.timeseries = resp.data;
        this.dataready = true;
        // Update KPI gauges with new value
        KPIs.map((kpi) => {
          let tag = kpi.tag!;
          this.kpiGauges[tag].value = this.updateGaugeValue(
            tag,
            this.timeseries,
          );
        });

        // Update OP gauges with new value
        OperatingConditions.map((param) => {
          let tag = param.tag!;
          this.opGauges[tag].value = this.updateGaugeValue(
            tag,
            this.timeseries,
          );
        });

        // Update Heat Pump Modes
        this.heatPumpModes = HeatPumpConditions.map((param) => {
          let tag = param.tag!;
          let value = this.updateGaugeValue(tag, this.timeseries);
          return {
            title: param.name,
            mode: value,
          };
        });
      });

    this.updateTime = new Date();
  }

  ngOnInit(): void {
    KPIs.map((kpi) => {
      this.setupKPIGauge(kpi);
    });

    OperatingConditions.map((param) => {
      this.setupOperatingConditionGauges(param);
    });
    this.kpis = KPIs.map((kpi) => kpi.tag!);
    this.params = OperatingConditions.map((param) => param.tag!);

    this.update();

    this.timer = setInterval(() => {
      this.update();
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}
