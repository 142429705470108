import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { timeBegan } from '../../monthly/helper/monthly.component.helper';
import { DateConverter } from '../../utils/date-converter';

const DateTimeFormat: string = 'YYYY-MM-ddTHH:mm';
export interface dateRange {
  start: string | null;
  end: string | null;
}

@Component({
  selector: 'app-datetime-selector',
  templateUrl: './datetime-selector.component.html',
  styleUrl: './datetime-selector.component.scss',
})
export class DatetimeSelectorComponent implements OnInit {
  minDate: string = '';
  startDate: string | null = null;
  endDate: string | null = null;
  @Output() dates = new EventEmitter<dateRange>();

  constructor(private dateConverter: DateConverter) {}

  onButtonClick(start: string, end: string): void {
    this.dates.emit({ start: start + ':00Z', end: end + ':00Z' });
  }

  ngOnInit(): void {
    this.minDate = this.dateConverter.toFormat(timeBegan, DateTimeFormat);
    // initialize start and end date
    let start = new Date();
    let end = new Date();
    start.setDate(1);
    start.setHours(0, 0, 0, 0);
    end.setSeconds(0, 0);
    // convert datetime format
    this.startDate = this.dateConverter.toFormat(start, DateTimeFormat);
    this.endDate = this.dateConverter.toFormat(end, DateTimeFormat);
    this.onButtonClick(this.startDate, this.endDate);
  }
}
