import { Component, OnInit, Input } from '@angular/core';
import { EffectValue } from '../helper/monthly.component.helper';

@Component({
  selector: 'app-value-display',
  templateUrl: './value-display.component.html',
  styleUrl: './value-display.component.scss',
})
export class ValueDisplayComponent implements OnInit {
  @Input() value: number = 0;
  @Input() uom: string = '';
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() values: EffectValue[] | undefined;
  singlevalue: boolean = true;
  monitary: boolean = false;
  ngOnInit(): void {
    this.monitary = this.uom == 'DKK';
    this.singlevalue = this.values?.length === 0 || this.values === undefined;

    this.values = this.singlevalue
      ? [
          {
            tag: this.subtitle,
            value: this.value,
            uom: this.uom,
          },
        ]
      : this.values;
  }
}
