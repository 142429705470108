import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { influxdatetimeformat, datetimeformat } from './def-constants';

@Injectable({
  providedIn: 'root',
})
export class DateConverter {
  constructor(private datePipe: DatePipe) {}

  toFormatUTC(date: Date, format: string): string {
    return this.datePipe.transform(date, format, 'UTC')!;
  }

  toFormat(date: Date, format: string): string {
    return this.datePipe.transform(date, format)!;
  }

  toDateTimeFormat(date: Date): string {
    return this.toFormat(date, datetimeformat);
  }

  toInfluxFormat(date: Date, truncate: boolean = true): string {
    let format = truncate
      ? influxdatetimeformat.replace('HH:mm:ss', '00:00:00')
      : influxdatetimeformat;
    return this.toFormat(date, format) + 'Z';
  }
}
