<mat-card>
  <mat-card-subtitle>{{ title }}</mat-card-subtitle>
  <mat-slide-toggle
    *ngIf="enableLimitToggle"
    color="primary"
    class="toggletext"
    [checked]="axLimits"
    (change)="updateLimits($event)"
    ><div class="toggletext">Fixed Limits</div></mat-slide-toggle
  >
  <div echarts id="{{ id }}" [options]="option" style="width: 600px; height: 400px"></div>
</mat-card>
