import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {
  InjectionToken,
  NgModule,
  ChangeDetectionStrategy,
  LOCALE_ID,
} from '@angular/core';
import {
  HttpClient,
  provideHttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import {
  ActionRequiredState,
  AppEnvironmentState,
  AttachmentsV2Module,
  BreadcrumbV2Module,
  ButtonV2Module,
  ComplexDialogV2Module,
  DialogV2Module,
  GeaMissingTranslationHandler,
  GeaTranslateHttpLoader,
  HeaderModule,
  HeaderConfig,
  InputV2Module,
  PageHeaderModule,
  SearchBarV2Module,
  SideModalV2Module,
  TableV2Module,
  UserState,
  PageLayoutModule,
  SidebarV2Module,
  PageContentLayoutComponent,
  MultiSelectV2Module,
  PermissionsState,
} from '@gea/digital-ui-lib';

import { MsalRedirectComponent } from '@azure/msal-angular';
import { NgxsModule } from '@ngxs/store';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
} from '@ngx-translate/core';
import { TreeModule } from 'primeng/tree';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfiguration,
} from '../environments/models/environment.model';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { provideNativeDateAdapter } from '@angular/material/core';
//
import { SlicePipe } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { DatePipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { JsonPipe } from '@angular/common';
//
import { DateConverter } from './utils/date-converter';
import { JwtInterceptor } from './utils/jwt.interceptor';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GaugeComponent } from './gauge/gauge.component';
import { MonthlyComponent } from './monthly/monthly.component';
import { ReportComponent } from './monthly/report/report.component';
import { LineComponent } from './line/line.component';
import { ValueDisplayComponent } from './monthly/value-display/value-display.component';
import { HeatPumpModeComponent } from './dashboard/heat-pump-mode/heat-pump-mode.component';
import { RunsTableComponent } from './analytics/runs-table/runs-table.component';
import { DatetimeSelectorComponent } from './analytics/datetime-selector/datetime-selector.component';
import { AnalyticsComponent } from './analytics/analytics.component';

const translationFactory = (
  http: HttpClient,
  environment: EnvironmentConfiguration,
) => {
  return new GeaTranslateHttpLoader(http, environment.localizationStorageURL, [
    'i18n/1/common/',
  ]);
};

export const HEADER_CONFIG = new InjectionToken<HeaderConfig>('HeaderConfig');

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    GaugeComponent,
    MonthlyComponent,
    ReportComponent,
    LineComponent,
    ValueDisplayComponent,
    HeatPumpModeComponent,
    RunsTableComponent,
    DatetimeSelectorComponent,
    AnalyticsComponent,
  ],
  imports: [
    NgxsModule.forRoot(
      [UserState, ActionRequiredState, AppEnvironmentState, PermissionsState],
      {
        compatibility: {
          strictContentSecurityPolicy: true,
        },
      },
    ),
    SlicePipe,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    TableV2Module,
    BreadcrumbV2Module,
    SearchBarV2Module,
    InputV2Module,
    TreeModule,
    PageLayoutModule,
    SidebarV2Module,
    PageHeaderModule,
    ButtonV2Module,
    DialogV2Module,
    NgxSkeletonLoaderModule,
    TranslateModule.forRoot({
      extend: true,
      defaultLanguage: 'en-US',
      useDefaultLang: false,
      loader: {
        deps: [HttpClient, ENVIRONMENT_CONFIG],
        provide: TranslateLoader,
        useFactory: translationFactory,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: GeaMissingTranslationHandler,
      },
    }),
    HeaderModule.forRoot(HEADER_CONFIG),
    SideModalV2Module,
    AttachmentsV2Module,
    ComplexDialogV2Module,
    ProgressSpinnerModule,
    PageContentLayoutComponent,
    MultiSelectV2Module,
    MatExpansionModule,
    MatGridListModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    MatTabsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTableModule,
    JsonPipe,
  ],
  providers: [
    provideHttpClient(),
    TranslatePipe,
    DatePipe,
    DateConverter,
    provideNativeDateAdapter(),
    {
      provide: 'subscriptionKey',
      useValue: '',
    },
    {
      provide: 'baseUrl',
      useFactory: (env: EnvironmentConfiguration) =>
        env.portal?.baseURL ?? env.baseURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'assetBaseUrl',
      useFactory: (env: EnvironmentConfiguration) =>
        (env.advance?.baseURL ?? env.baseURL) + 'asset/v1',
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'storageURL',
      useFactory: (env: EnvironmentConfiguration) => env.localizationStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'iconPath',
      useFactory: (env: EnvironmentConfiguration) => env.iconStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
