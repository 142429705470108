import {
  Component,
  Input,
  OnInit,
  OnChanges,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { TimePeriod, TimeSeries } from '../models/timeseries.model';
import { LineChartConfig } from '../models/line-config.model';
import { DateConverter } from '../utils/date-converter';
import { datetimeformat } from '../utils/def-constants';
import { mean, round } from '../utils/time-period-utils';

const invalidcolor = '#BFE3FF';
const boundarycolor = '#1F9DFF';
const validareacolor = 'rgba(21, 189, 94, 0.25)';
const validcolor = '#1F9DFF';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrl: './line.component.scss',
})
export class LineComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() id: string = 'line-chart';
  @Input() lower?: number | null = null;
  @Input() upper?: number | null = null;
  @Input() timeseries: TimeSeries = {};
  @Input() lineconfig: LineChartConfig = {};
  @Input() tag: string = '';
  @Input() valid: TimePeriod[] = [];
  @Input() showmean: boolean = false;
  @Input() enableLimitToggle: boolean = false;
  axLimits: boolean = true;
  chartInstance: any;
  option: EChartsOption = {};
  title: string = '';

  constructor(private dateConverter: DateConverter) {}

  initChart(id: string) {
    // Initial chart update
    const chartElement = document.getElementById(id);
    if (chartElement) {
      if (!this.chartInstance) {
        // Initialize the chart
        this.chartInstance = echarts.init(chartElement);
      }
      // Proceed with any additional chart configuration or data loading
      this.chartInstance.resize({
        width: 600,
        // height: 400,
      });
    }
    this.update();
  }

  getDefaultOptions(
    vals: [string, number][],
    meanvalue: number | null,
    validPeriods: any[],
  ): EChartsOption {
    const option: EChartsOption = {
      color: ['#3fb1e3', '#6be6c1', '#626c91', '#a0a7e6', '#c4ebad', '#96dee8'],
      backgroundColor: '#ffffff',
      title: {
        text: '', //this.lineconfig.title || '',
        subtext: this.lineconfig.yAxisLabel || '',
        textStyle: {
          color: '#141414',
        },
        subtextStyle: {
          color: '#141414',
          fontSize: '11px',
        },
      },
      // line: {
      //   itemStyle: {
      //     borderWidth: '2',
      //   },
      //   lineStyle: {
      //     width: '3',
      //   },
      //   symbolSize: '8',
      //   symbol: 'emptyCircle',
      //   smooth: false,
      // },
      visualMap: {
        show: false,
        pieces:
          this.lower && this.upper
            ? [
                { lte: this.lower, color: invalidcolor },
                { gt: this.lower, lte: this.upper, color: validcolor },
                { gt: this.upper, color: invalidcolor },
              ]
            : [{ gt: -99999, lte: 99999, color: validcolor }],
        outOfRange: {
          color: '#999',
        },
      },
      xAxis: {
        type: 'category',
        data: vals.map((val) => val[0]),
        name: this.lineconfig.xAxisLabel || '',
        axisLabel: {
          formatter: `{value} ${this.lineconfig.xTickLabel || ''}`,
          rotate: 0,
        },
      },
      grid: { containLabel: false, show: false },
      yAxis: {
        type: 'value',
        min: this.axLimits ? this.lineconfig.yAxisMin : undefined,
        max: this.axLimits ? this.lineconfig.yAxisMax : undefined,
        scale: true,
        axisLabel: { formatter: `{value} ${this.lineconfig.yTickLabel || ''}` },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          lineStyle: {
            color: '#cccccc',
            width: 1,
          },
          crossStyle: {
            color: '#cccccc',
            width: 1,
          },
        },
      },
      legend: {
        show: true,
        data: [this.tag],
        textStyle: {
          color: '#141414',
          fontSize: '11px',
        },
      },
      series: [
        {
          data: vals.map((val) => val[1]),
          type: 'line',
          name: this.tag,
          symbol: 'none',
          markLine: {
            silent: true,
            symbol: 'none',
            lineStyle: {
              color: boundarycolor,
            },
            data:
              this.lower && this.upper
                ? [
                    { yAxis: this.lower },
                    { yAxis: this.upper },
                    { yAxis: meanvalue ?? '' },
                  ]
                : [],
          },
          markArea: {
            itemStyle: { color: validareacolor },
            data: validPeriods,
          },
        },
      ],
    };
    return option;
  }

  updateLimits(event: any): void {
    this.axLimits = event.checked;
    this.option.yAxis = {
      type: 'value',
      min: this.axLimits ? this.lineconfig.yAxisMin : undefined,
      max: this.axLimits ? this.lineconfig.yAxisMax : undefined,
      scale: true,
      axisLabel: { formatter: `{value} ${this.lineconfig.yTickLabel || ''}` },
    };

    if (this.chartInstance) {
      this.chartInstance.setOption(this.option);
    }
  }

  update(): void {
    this.title = this.lineconfig.title || '';
    const dateformat = this.lineconfig.datetimeformat! || datetimeformat;
    const vals = this.prepTimeSeries(this.timeseries ?? {}, dateformat);
    let meanvalue =
      Object.values(this.timeseries || {}).length > 0 && this.showmean
        ? mean(this.timeseries)
        : null;
    const validPeriods = this.prepValidPeriods(this.valid, dateformat);
    this.option = this.getDefaultOptions(vals, meanvalue, validPeriods);
  }

  ngAfterViewInit(): void {
    this.initChart(this.id);
  }

  ngOnInit(): void {
    this.initChart(this.id);
  }

  ngOnChanges(): void {
    this.update();
  }

  ngOnDestroy(): void {
    if (!this.chartInstance.isDisposed()) {
      this.chartInstance.dispose();
    }
  }

  prepValidPeriods(validSeries: TimePeriod[], dateformat: string): any[] {
    // extract time periods for markArea display
    const validPeriods: any[] = [];
    validSeries.forEach((entry) =>
      validPeriods.push([
        {
          name: '',
          xAxis: this.dateConverter.toFormat(new Date(entry.start), dateformat),
        },
        { xAxis: this.dateConverter.toFormat(new Date(entry.end), dateformat) },
      ]),
    );
    return validPeriods;
  }

  prepTimeSeries(
    timeseries: TimeSeries,
    dateformat: string,
  ): [string, number][] {
    // get timeseries timestamps and values
    const vals = Object.entries(timeseries || {});
    // Convert to formatted time string
    if (this.lineconfig.datetimeformat != 'none') {
      vals.forEach((val) => {
        val[0] = this.dateConverter.toFormatUTC(new Date(val[0]), dateformat);
      });
    }
    // Round to three decimals
    vals.forEach((val) => {
      val[1] = round(val[1], this.lineconfig.decimals! || 3);
    });
    return vals;
  }
}
