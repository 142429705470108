import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateConverter } from '../../utils/date-converter';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss',
})
export class ReportComponent implements OnInit {
  start: string = '';
  constructor(
    private dateConverter: DateConverter,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // Get todays date and set start date to first of month
    const startDate: Date = new Date();
    startDate.setDate(1);
    // Subtract a month from start date, to get previous month as active month is not yet finished
    startDate.setMonth(startDate.getMonth() - 1);
    const endDate: Date = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0,
    );
    endDate.setMonth(startDate.getMonth() + 1);
    const startDateStr = this.dateConverter.toFormat(startDate, 'YYYYMM');

    if (true) {
      // if token is ready
      // navigate to report/:month
      const navroute = `/report/${startDateStr}`;
      this.router.navigate([navroute]);
    }
  }
}
