import { Component, Input, OnInit } from '@angular/core';
import { red, green } from '../../utils/def-constants';

@Component({
  selector: 'app-heat-pump-mode',
  templateUrl: './heat-pump-mode.component.html',
  styleUrl: './heat-pump-mode.component.scss',
})
export class HeatPumpModeComponent implements OnInit {
  @Input() title: string = 'Heat Pump Mode';
  @Input() mode: number | null = null;
  status: string = 'Unknown';
  color: string = 'black';

  ngOnInit(): void {
    if (this.title.includes('Heat Pump')) {
      this.status = this.mode! === 0 ? 'DEACTIVATED' : 'ACTIVATED';
      this.color = this.status === 'DEACTIVATED' ? red : green;
    }
    if (this.title.includes('Sequencer')) {
      this.status = this.mode!.toFixed(0).toString();
    }
  }
}
