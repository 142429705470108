import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Store } from '@ngxs/store';
import { UserState } from '@gea/digital-ui-lib';
import {
  EnvironmentConfiguration,
  ENVIRONMENT_CONFIG,
} from '../../environments/models/environment.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContentObserver } from '@angular/cdk/observers';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token = '';

  constructor(
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration,
  ) {
    this.store.select(UserState.token).subscribe((token) => {
      this.token = token;
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const isApiUrl = request.url.startsWith(
      this.environment.serviceCall.baseURL!,
    );
    if (isApiUrl && this.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
        },
      });
    }
    return next.handle(request);
  }
}
