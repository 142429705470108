<mat-expansion-panel [expanded]="true" hideToggle="true">
  <mat-expansion-panel-header>
    <mat-panel-title>Current Performance</mat-panel-title>
  </mat-expansion-panel-header>

  <mat-grid-list [cols]="cols$ | async" rowHeight="320px">
    <mat-grid-tile *ngFor="let tag of kpis" [colspan]="1" [rowspan]="1">
      <app-gauge
        [id]="kpiGauges[tag].id"
        [gaugecfg]="kpiGauges[tag].config"
        [value]="kpiGauges[tag].value"
        *ngIf="dataready"
      ></app-gauge>
      <div *ngIf="!dataready" class="loading-container">
        <p-progressSpinner animationDuration="2s" strokeWidth="4">
        </p-progressSpinner>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</mat-expansion-panel>

<mat-expansion-panel [expanded]="true" hideToggle="true">
  <mat-expansion-panel-header>
    <mat-panel-title>Operating Conditions</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-grid-list [cols]="cols$ | async" rowHeight="320px">
    <mat-grid-tile *ngFor="let tag of params" [colspan]="1" [rowspan]="1">
      <app-gauge
        [id]="opGauges[tag].tag"
        [gaugecfg]="opGauges[tag].config"
        [value]="opGauges[tag].value"
        *ngIf="dataready"
      ></app-gauge>
      <div *ngIf="!dataready" class="loading-container">
        <p-progressSpinner animationDuration="2s" strokeWidth="4">
        </p-progressSpinner>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <div *ngIf="dataready">
        <div *ngFor="let hp of heatPumpModes">
          <app-heat-pump-mode
            [title]="hp.title"
            [mode]="hp.mode"
          ></app-heat-pump-mode>
        </div>
      </div>
      <div *ngIf="!dataready" class="loading-container">
        <p-progressSpinner animationDuration="2s" strokeWidth="4">
        </p-progressSpinner>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="updateTime">
    Latest update at {{ updateTime | date: "YYYY-MM-dd HH:mm:ss" }}
  </div>
</mat-expansion-panel>
