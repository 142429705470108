import {
  TimeSeries,
  TimePeriod,
  BooleanTimeSeries,
} from '../models/timeseries.model';

// function takes in a binary time series and returns an array of time periods where the values are connected
export function extractTimePeriods(
  timeSeries: BooleanTimeSeries,
): TimePeriod[] {
  const connectedPeriods: TimePeriod[] = [];
  let startTime: string | null = null;
  // Loop through the time series entries
  Object.entries(timeSeries).forEach(([timestamp, value], index) => {
    if (value && startTime === null) {
      startTime = timestamp;
    } else if (!value && startTime !== null) {
      let prevTimestamp = Object.keys(timeSeries)[index - 1];
      connectedPeriods.push({ start: startTime, end: prevTimestamp });
      startTime = null;
    }
  });

  return connectedPeriods;
}

// function takes a time series and returns the mean value
export function mean(timeSeries: TimeSeries): number {
  const values = Object.values(timeSeries);
  const mean =
    values.length > 0 ? values.reduce((a, b) => a + b) / values.length : 0;
  return mean;
}

// function takes a time series and returns the mean value
export function conditionalMean(
  timeSeries: TimeSeries,
  flag: BooleanTimeSeries,
): number {
  const values = Object.values(timeSeries);
  const flagValues = Object.values(flag);

  // Filter out the values that are not valid
  const validValues = values.filter((value, index) => flagValues[index]);
  const mean =
    validValues.length > 0
      ? validValues.reduce((a, b) => a + b) / validValues.length
      : 0;
  return mean;
}

// function to round to decimals
export function round(value: number, decimals: number): number {
  const scale = Math.pow(10, decimals);
  return Math.round(value * scale) / scale;
}

export function getYearMonthArray(
  startDate: Date,
  endDate: Date,
): Array<[number, number]> {
  const yearMonthArray: Array<[number, number]> = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
    yearMonthArray.push([year, month]);
    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return yearMonthArray;
}
