import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { TimeSeriesResponse } from '../models/timeseries.model';
import { Observable } from 'rxjs';
import {
  EnvironmentConfiguration,
  ENVIRONMENT_CONFIG,
} from '../../environments/models/environment.model';

@Injectable({
  providedIn: 'root',
})
export class TimeSeriesService {
  constructor(
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration,
  ) {}

  getTimeseries(
    tag: string[],
    start: string | null,
    end: string | null,
    resolution: string | null,
    includeflag: boolean,
    _includeSequencerMode?: boolean,
    _includeHeatPumpActivity?: boolean,
  ): Observable<TimeSeriesResponse> {
    var baseUrl = this.environment.serviceCall.baseURL!;
    baseUrl = baseUrl.slice(-1) === '/' ? baseUrl : baseUrl + '/';
    baseUrl = `${baseUrl}telemetry`;
    const includesequencermode = _includeSequencerMode ?? false;
    const includeheatpumpactivity = _includeHeatPumpActivity ?? false;
    return this.httpClient.post<TimeSeriesResponse>(baseUrl, {
      tag,
      start,
      end,
      resolution,
      includeflag,
      includesequencermode,
      includeheatpumpactivity,
    });
  }
}
