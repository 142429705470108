import { GaugeConfig } from '../../models/gauge-config.model';
import { getYearMonthArray } from '../../utils/time-period-utils';
import { red, green, blue } from '../../utils/def-constants';
import { OperatingConditions } from '../../../assets/settings/measurable';
import { Measurable } from '../../../assets/settings/models/measurable.model';

export const timeBegan: Date = new Date(2024, 8 - 1, 1); // Months are zero-indexed

export interface EffectValue {
  tag: string;
  value: number;
  uom: string;
}

export interface DisplayValue {
  tag: string;
  title: string;
  subtitle: string;
  uom: string;
  value: number;
  values?: EffectValue[];
}
export interface DisplayValues {
  [tag: string]: DisplayValue;
}

export interface CompensationResponse {
  compensation_DKK: number;
  total_electricity_consumption_kWh: number;
  total_cooling_icewater_kWh: number;
  total_heating_effect_kWh: number;
  active_electricity_consumption_kWh: number;
  active_cooling_icewater_kWh: number;
  active_heating_effect_kWh: number;
  electricity_consumption_kWh: number;
  cooling_icewater_kWh: number;
  heating_effect_kWh: number;
  total_hours_mode_1: number;
  total_hours_mode_2: number;
  total_valid_hours: number;
}

export interface Gauges {
  [tag: string]: Gauge;
}

export interface Gauge {
  tag: string;
  config: GaugeConfig;
  value: number;
  label?: string;
}

export interface MonthOptions {
  year: number;
  month: number;
  value: string;
  label: string;
}

export function setupParamGauges(): Gauges {
  let gauges: Gauges = {};

  // setup gauges for OpCond
  OperatingConditions.forEach((param: Measurable) => {
    let config: GaugeConfig = {
      title: param.name!,
      subtitle: 'Average Value',
      uom: param.uom!,
      min: param.zero!,
      max: param.peak!,
      decimals: 1,
      levels: [
        { value: param.min!, color: red },
        { value: param.max!, color: green },
        { value: param.peak!, color: red },
      ],
    };
    gauges[param.tag] = { tag: param.tag, config: config, value: 0, label: '' };
  });

  // let runtime_config: GaugeConfig = {
  //   title: 'Valid Operating Time',
  //   subtitle: '% (valid hours / total hours)',
  //   uom: '%',
  //   min: 0,
  //   max: 100,
  //   decimals: 1,
  //   levels: [{ value: 100, color: blue }],
  // };
  // gauges['valid_time'] = {
  //   tag: 'valid_time',
  //   config: runtime_config,
  //   value: 0,
  //   label: '',
  // };

  return gauges;
}

export function setupStatusValues(): DisplayValues {
  let statusDisplay: DisplayValues = {};
  statusDisplay['heating_effect_kWh'] = {
    tag: 'heating_effect_kWh',
    value: 1,
    title: 'Heating Effect to Main Air', // Performance',
    subtitle: '',
    uom: 'kWh',
    values: [
      {
        tag: 'Total Heating',
        value: 0,
        uom: 'kWh',
      },
      {
        tag: 'Total During Valid Periods',
        value: 0,
        uom: 'kWh',
      },
      {
        tag: 'Total Under-Performance',
        value: 0,
        uom: 'kWh',
      },
    ],
  };
  statusDisplay['cooling_icewater_kWh'] = {
    tag: 'cooling_icewater_kWh',
    value: 0,
    title: 'Cooling Effect to Ice Water',
    subtitle: 'Aggregated Compensation Factor',
    uom: 'kWh',
    values: [
      {
        tag: 'Total Cooling to Ice Water',
        value: 0,
        uom: 'kWh',
      },
      {
        tag: 'Total During Valid Periods',
        value: 0,
        uom: 'kWh',
      },
      {
        tag: 'Total Under-Performance',
        value: 0,
        uom: 'kWh',
      },
    ],
  };
  statusDisplay['electricity_consumption_kWh'] = {
    tag: 'electricity_consumption_kWh',
    value: 0,
    title: 'Electricity Consumption', // Performance',
    subtitle: 'Aggregated Compensation Factor',
    uom: 'kWh',
    values: [
      {
        tag: 'Total Electricity Consumption',
        value: 0,
        uom: 'kWh',
      },
      {
        tag: 'Total During Valid Periods',
        value: 0,
        uom: 'kWh',
      },
      {
        tag: 'Total Under-Performance',
        value: 0,
        uom: 'kWh',
      },
    ],
  };
  statusDisplay['valid_hours'] = {
    tag: 'valid_hours',
    value: 0,
    title: 'Operating Validity',
    subtitle: 'Total Valid Operating Hours',
    uom: 'hours',
    values: [
      {
        tag: 'Total Operating Hours in Mode 1',
        value: 0,
        uom: 'hours',
      },
      {
        tag: 'Total Operating Hours in Mode 2',
        value: 0,
        uom: 'hours',
      },

      {
        tag: 'Total Valid Hours',
        value: 0,
        uom: 'hours',
      },
    ],
  };
  statusDisplay['compensation'] = {
    tag: 'compensation',
    value: 0,
    title: 'Estimated Compensation',
    subtitle: 'Aggregated Compensation',
    uom: 'DKK',
  };
  return statusDisplay;
}

export function getStartEndDates(selectedMonth: string): [Date, Date] {
  const start = parseDateString(selectedMonth);
  const end = new Date(start);
  end.setMonth(end.getMonth() + 1);
  return [start, end];
}

export function formatOptionsString(year: number, month: number): string {
  return `${year}/${month.toString().padStart(2, '0')}`;
}

export function generateMonthOptions(): MonthOptions[] {
  const options: MonthOptions[] = [];
  const yearMonthArray = getYearMonthArray(timeBegan, new Date());
  yearMonthArray.reverse().forEach(([year, month]) => {
    options.push({
      year: year,
      month: month,
      value: formatOptionsString(year, month).replace('/', ''),
      label: formatOptionsString(year, month),
    });
  });
  return options;
}

export function parseDateString(value: string): Date {
  const regex = new RegExp('([0-9]{4})([0-9]{2})');
  const match = regex.exec(value);

  if (value != '' && match) {
    // subtracting 1 from month, as months are zero-indexed
    return new Date(Number(match[1]), Number(match[2]) - 1, 1);
  } else return new Date();
}
