import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { EChartsOption } from 'echarts';
import { GaugeConfig } from '../models/gauge-config.model';
import { round } from '../utils/time-period-utils';
import * as echarts from 'echarts';

const gaugeStartAngle: number = 188;
const gaugeendAngle: number = -8;
const centerOffset: (string | number)[] = ['50%', '74%'];
const radius: (string | number)[] = ['100%', '94%'];

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrl: './gauge.component.scss',
})
export class GaugeComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() id: string = 'chart';
  @Input() value: number = 0;
  @Input() gaugecfg: GaugeConfig = {
    title: 'Default Title',
    subtitle: 'Tag',
    uom: '',
    levels: [{ value: 1.0, color: '#00ff00' }],
    min: 0,
    max: 100,
  };
  chartInstance: any;
  gauge_option: EChartsOption = {};
  axisColorLevels: any = [[0.0, '#ffffff']];
  label: string = 'label';
  valueColor: string = '#000082'; // default color
  chartSize = 240;
  constructor() {}
  update(value: number): void {
    if (this.chartInstance) {
      this.updateChart(isNaN(value) ? 0.0 : value);
    }
  }
  initChart(value: number, id: string) {
    // Initial chart update
    // document.
    const chartElement = document.getElementById(id);
    if (chartElement) {
      if (!this.chartInstance) {
        // Initialize the chart
        this.chartInstance = echarts.init(chartElement);
      }
      // Proceed with any additional chart configuration or data loading
      this.chartInstance.resize({
        width: this.chartSize,
        height: this.chartSize,
      });
    }
    this.update(value);
  }
  replaceLastSpaceWithNewLine(str: string, maxLength: number = 30): string {
    let result = str;
    const index = str.lastIndexOf(' ');
    if (index != -1 && index < maxLength) {
      result = str.substring(0, index) + '\n' + str.substring(index + 1);
    } else if (index != -1 && index >= maxLength) {
      let last = str.substring(index);
      let first = this.replaceLastSpaceWithNewLine(
        str.substring(0, index),
        maxLength,
      );
      result = first + last;
    }
    return result;
  }
  updateChart(value: number) {
    // truncate value to get correct color
    let truncatedValue = value > this.gaugecfg.max ? this.gaugecfg.max : value;
    truncatedValue =
      truncatedValue < this.gaugecfg.min ? this.gaugecfg.min : truncatedValue;
    let valueColor = String(
      this.gaugecfg.levels?.find((item) => item.value >= truncatedValue)?.color,
    );
    let axisColorLevels: any = [];
    this.gaugecfg.levels?.forEach((v) =>
      axisColorLevels.push([
        (Number(v.value) - this.gaugecfg.min) /
          (this.gaugecfg.max - this.gaugecfg.min),
        String(v.color),
      ]),
    );
    // Round the value to 2 decimal places
    let datavalue = round(value, 2);
    // Ensure that the value is not negative
    datavalue = datavalue > 0 ? datavalue : 0;
    this.gauge_option = {
      title: {
        text:
          this.gaugecfg.title.length < 30
            ? this.gaugecfg.title
            : this.replaceLastSpaceWithNewLine(this.gaugecfg.title),
        subtext: this.gaugecfg.subtitle,
        textStyle: {
          fontSize: 12,
        },
        top: 'top',
        left: 'left',
      },
      series: [
        {
          id: 'axis',
          data: [{ name: 'axis', value: 0, detail: { show: false } }],
          title: { show: false },
          startAngle: gaugeStartAngle,
          endAngle: gaugeendAngle,
          clockwise: true,
          min: 0,
          max: 1,
          radius: radius.at(0),
          axisLine: {
            show: true,
            roundCap: false,
            lineStyle: {
              color: axisColorLevels,
              width: 5,
              opacity: 1.0,
            },
          },
          type: 'gauge',
          center: centerOffset,
          progress: { show: false },
          pointer: { show: false },
          splitLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
        },
        {
          id: 'value',
          data: [
            {
              name: '',
              value: datavalue,
              detail: {
                valueAnimation: false,
                offsetCenter: [0, '-8%'],
                fontSize: 18,
                fontWeight: 'bolder',
                formatter:
                  `${datavalue.toFixed(this.gaugecfg.decimals!)}` +
                  (this.gaugecfg.uom.startsWith('\u00b0') ||
                  this.gaugecfg.uom == '%'
                    ? ''
                    : ' ') +
                  this.gaugecfg.uom,
                color: valueColor,
              },
            },
          ],
          title: { show: false },
          startAngle: gaugeStartAngle,
          endAngle: gaugeendAngle,
          clockwise: true,
          min: this.gaugecfg.min,
          max: this.gaugecfg.max,
          radius: radius.at(1),
          center: centerOffset,
          type: 'gauge',
          axisLine: {
            show: true,
            lineStyle: { width: 40 },
          },
          progress: {
            show: true,
            width: 40,
            itemStyle: { color: 'inherent' },
          },
          pointer: { show: false },
          splitLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
        },
      ],
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        containLabel: false, // Ensure that labels are contained within the grid area
      },
      padding: [0, 0, 0, 0], // Set padding to 0 on all sides
    };
    if (this.chartInstance) {
      this.chartInstance.setOption(this.gauge_option);
    }
  }
  ngAfterViewInit(): void {
    this.initChart(this.value, this.id);
  }
  ngOnInit(): void {
    this.initChart(this.value, this.id);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.update(changes['value'].currentValue);
    this.value = changes['value'].currentValue;
  }
  ngOnDestroy(): void {
    if (!this.chartInstance.isDisposed()) {
      this.chartInstance.dispose();
    }
  }
}
