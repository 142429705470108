<div class="selectors">
  <div class="selector">
    <div class="dateSelector">
      <label for="fromdate">Start Date</label>
      <input
        #startDateRange
        [(ngModel)]="startDate"
        type="datetime-local"
        id="startdate"
        name="startdate"
        class="dateform"
        pattern="YYYY-MM-ddTHH:mm"
        min="{{ minDate }}"
      />
    </div>
  </div>
  <div class="selector">
    <div class="dateSelector">
      <label for="enddate">End Date</label>
      <input
        #endDateRange
        [(ngModel)]="endDate"
        type="datetime-local"
        id="enddate"
        name="enddate"
        class="dateform"
        pattern="YYYY-MM-ddTHH:mm"
        min="{{ minDate }}"
      />
    </div>
  </div>
  <div class="button">
    <button
      [disabled]="false"
      mat-raised-button
      color="primary"
      (click)="onButtonClick(startDateRange.value, endDateRange.value)"
    >
      Update
    </button>
  </div>
</div>
